@tailwind base;
@tailwind components;
@tailwind utilities;

/* .features-tab .slick-list {
  mask-image: linear-gradient(
    to right,
    transparent,
    black 100px,
    black calc(100% - 100px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    black 50px,
    black calc(100% - 50px),
    transparent
  );
} */

.features-tab .slick-slide {
  position: relative;
}

.features-tab .slick-slide::before,
.features-tab .slick-slide:hover::before {
  height: 4px !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.features-tab .slick-current::before {
  content: "";
  width: 80%;
  height: 0;
  background: #aebd48;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 1;
}
