/* src/fonts/human-sans/fonts.css */

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-BlackOblique.otf") format("opentype");
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-BoldOblique.otf") format("opentype");
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-ExtraLightOblique.otf") format("opentype");
  font-weight: 200;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-ExtraLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-LightOblique.otf") format("opentype");
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-MediumOblique.otf") format("opentype");
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-RegularOblique.otf") format("opentype");
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-ThinOblique.otf") format("opentype");
  font-weight: 100;
  font-style: oblique;
}

@font-face {
  font-family: "Human Sans";
  src: url("./HumanSans-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
